import axios from '@/axios';
import {userService} from '@/services/userService'

export const state = {
    token: localStorage.getItem('access_token'),
    user: null,
    company: null
}

export const mutations = {
    setToken(state, token) {
        state.token = token
    },
    setUser(state, user) {
        state.user = user
    },
    destroyToken(state) {
        state.token = null
        state.user = null
    },
}

export const actions = {
    login({commit}, credentials) {
        return new Promise((resolve, reject) => {
            userService.login(credentials).then(response => {
                localStorage.setItem('access_token', response.data.access_token)
                commit('setToken', response.data.access_token)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    destroyToken({commit}) {
        commit('destroyToken')
        localStorage.clear()
    },
    setToken({commit}, token) {
        localStorage.setItem('access_token', token)
        commit('setToken', token)
    },
    logout({getters, commit}) {
        if (getters.loggedIn) {
            userService.logout()
            commit('destroyToken')
            localStorage.clear()
        }
    },
    getUser({commit, state}) {
        if (state.token === null) {
            return
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
        return new Promise((resolve, reject) => {
            userService.getUser()
                .then(response => {
                    commit('setUser', response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}

export const getters = {
    loggedIn(state) {
        return state.token !== null
    },
    user(state) {
        return state.user
    },
    company(state) {
        if (!state.company) {
            userService.getCompany().then(response => state.company = response.data.data)
        }
        return state.company
    },
    user_type(state) { //@todo change to userType
        return state.user?.user_type
    },
    isCandidate(state) {
        return state.user?.user_type === 'Candidate'
    },
    isClient(state) {
        return state.user?.user_type === 'Client'
    },
    isManagement(state) {
        return state.user?.user_type === 'Management'
    },
    isSuperAdmin(state) {
        return state.user?.user_type === 'SuperAdmin'
    },
}