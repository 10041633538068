import axios from "@/axios";

export const state = {
    employmentPeriods: null
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    employmentPeriods(state) {
        if (!state.employmentPeriods) {
            axios.get(`/employmentperiods`).then(response => state.employmentPeriods = response.data.data)
        }
        return state.employmentPeriods
    }
}