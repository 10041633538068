import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import ForCompanies from '../views/ForCompanies.vue'
import Contacts from '../views/Contacts.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Pricing from '../views/Pricing.vue'
import NotFound from '../views/NotFound'
import store from '../store'
import {cookieService} from "@/services/cookieService";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/for-companies',
        name: 'ForCompanies',
        component: ForCompanies,
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/terms-of-service',
        name: 'Terms',
        component: Terms,
    },
    {
        path: '/privacy-policy',
        name: 'Privacy',
        component: Privacy,
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing,
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import("@/views/onboarding/Onboarding"),
    },
    {
        path: '/vacancies/:slug-:id(\\d+)',
        name: 'ViewVacancy',
        component: () => import("@/views/vacancies/ViewVacancy"),
    },
    {
        path: '/vacancies/:slug-:id(\\d+)/edit',
        name: 'EditVacancy',
        component: () => import("@/views/vacancies/EditVacancy"),
        meta: {
            allowed: ['SuperAdmin', 'Client', 'Management'],
            permission: 'can_update_vacancies',
            requiresAuth: true,
        },
    },
    {
        path: '/vacancies',
        name: 'Vacancies',
        component: () => import("@/views/vacancies/Vacancies"),
    },
    {
        path: '/applications',
        name: 'Applications',
        component: () => import("@/views/applications/Applications"),
        meta: {
            allowed: ['Candidate', 'Client', 'Management'],
            requiresAuth: true,
        },
    },
    {
        path: '/my/vacancies',
        name: 'MyVacancies',
        component: () => import("@/views/vacancies/MyVacancies"),
        meta: {
            allowed: ['Client', 'Management'],
            permission: 'can_view_vacancies',
            requiresAuth: true,
        },
    },
    {
        path: '/vacancies/add',
        name: 'AddVacancy',
        component: () => import("@/views/vacancies/AddVacancy"),
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import("@/views/invoices/Invoices"),
    },
    {
        path: '/invoices/add',
        name: 'AddInvoice',
        component: () => import("@/views/invoices/AddInvoice"),
    },
    {
        path: '/invoices/:id(\\d+)',
        name: 'ViewInvoice',
        component: () => import("@/views/invoices/ViewInvoice")
    },
    {
        path: '/invoices/:id(\\d+)/edit',
        name: 'EditInvoice',
        component: () => import("@/views/invoices/EditInvoice")
    },
    {
        path: '/candidates/:id(\\d+)',
        name: 'ViewCandidate',
        component: () => import("@/views/candidates/ViewCandidate"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/candidates/:id(\\d+)/edit',
        name: 'EditCandidate',
        component: () => import("@/views/candidates/EditCandidate"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/candidates',
        name: 'Candidates',
        component: () => import("@/views/candidates/Candidates"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/clients/:id(\\d+)',
        name: 'ViewClient',
        component: () => import("@/views/companies/ViewCompany"),
        meta: {
            allowed: ['Management'],
            requiresAuth: true,
        },
    },
    {
        path: '/clients/:id(\\d+)/edit',
        name: 'EditClient',
        component: () => import("@/views/companies/EditCompany"),
        meta: {
            allowed: ['Management'],
            requiresAuth: true,
        },
    },
    {
        path: '/clients',
        name: 'Clients',
        component: () => import("@/views/companies/Companies"),
        meta: {
            allowed: ['Management'],
            requiresAuth: true,
        },
    },
    {
        path: '/settings/categories',
        name: 'SettingsCategories',
        component: () => import("@/views/settings/ViewAllSettings")
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import("@/views/settings/Settings"),
        meta: {
            allowed: ['SuperAdmin', 'Client', 'Management'],
            requiresAuth: true,
        },
        children: [
            {
                path: 'users/:group',
                name: 'Users',
                component: () => import("@/views/settings/users/ViewAllUsers")
            },
            {
                path: 'users/:id(\\d+)',
                name: 'ViewUser',
                component: () => import("@/views/settings/users/ViewUser")
            },
            {
                path: 'users/:id(\\d+)/edit',
                name: 'EditUser',
                component: () => import("@/views/settings/users/EditUser")
            },
            {
                path: 'usergroups/:group',
                name: 'UserGroups',
                component: () => import("@/views/settings/usergroups/UserGroups")
            },
            {
                path: 'usertypes/:group',
                name: 'UserTypes',
                component: () => import("@/views/settings/usertypes/UserTypes"),
                meta: {
                    allowed: ['SuperAdmin'],
                    requiresAuth: true,
                },
            },
            {
                path: 'translations',
                name: 'Translations',
                component: () => import("@/views/settings/translations/Translations"),
                meta: {
                    allowed: ['SuperAdmin'],
                    requiresAuth: true,
                },
            },
            {
                path: 'industries',
                name: 'Industries',
                component: () => import("@/views/settings/industries/Industries"),
                meta: {
                    allowed: ['Management'],
                    requiresAuth: true,
                },
            },
            {
                path: 'industries/:id(\\d+)/edit',
                name: 'EditIndustry',
                component: () => import("@/views/settings/industries/EditIndustry"),
                meta: {
                    allowed: ['Management'],
                    requiresAuth: true,
                },
            },
            {
                path: 'customfields',
                name: 'CustomFields',
                component: () => import("@/views/settings/customfields/CustomFields"),
            },
            {
                path: 'events',
                name: 'Events',
                component: () => import("@/views/settings/events/Events")
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: () => import("@/views/settings/notifications/Notifications")
            },
            {
                path: 'workflows',
                name: 'Workflows',
                component: () => import("@/views/settings/workflows/Workflows")
            },
            {
                path: 'general',
                name: 'GeneralSettings',
                component: () => import("@/views/settings/generalsettings/GeneralSettings")
            },
        ]
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import("@/views/profile/Index"),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'EditProfile',
                component: () => import("@/views/profile/EditProfile")
            },
            {
                path: 'achievements',
                name: 'EditAchievements',
                component: () => import("@/views/profile/candidate/Achievements")
            },
            {
                path: 'certificates',
                name: 'EditCertificates',
                component: () => import("@/views/profile/candidate/Certificates")
            },
            {
                path: 'courses',
                name: 'EditCourses',
                component: () => import("@/views/profile/candidate/Courses")
            },
            {
                path: 'education',
                name: 'EditEducation',
                component: () => import("@/views/profile/candidate/Education")
            },
            {
                path: 'experience',
                name: 'EditExperience',
                component: () => import("@/views/profile/candidate/Experience")
            },
            {
                path: 'hobbies',
                name: 'EditHobbies',
                component: () => import("@/views/profile/candidate/Hobbies")
            },
            {
                path: 'internship',
                name: 'EditInternship',
                component: () => import("@/views/profile/candidate/Internship")
            },
            {
                path: 'languages',
                name: 'EditLanguages',
                component: () => import("@/views/profile/candidate/Languages")
            },
            {
                path: 'skills',
                name: 'EditSkills',
                component: () => import("@/views/profile/candidate/Skills")
            }
        ]
    },
    {
        path: '/my/company',
        name: 'MyCompany',
        component: () => import("@/views/company/MyCompany"),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'ViewMyCompany',
                component: () => import("@/views/company/ViewMyCompany"),
            },
            {
                path: 'edit',
                name: 'EditMyCompany',
                component: () => import("@/views/company/EditMyCompany"),
                meta: {
                    requiresAuth: true,
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/user/Login"),
    },
    {
        path: '/auth/redirect/:network',
        name: 'SocialAuthorizationRedirect',
        component: () => import("@/views/user/SocialAuthorizationRedirect"),
    },
    {
        path: '/auth/callback/:network',
        name: 'SocialAuthorizationCallback',
        component: () => import("@/views/user/SocialAuthorizationCallback"),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import("@/views/user/Register"),
    },
    {
        path: '/verify/email/:id/:hash',
        name: 'VerifyEmail',
        component: () => import("@/views/user/VerifyEmail"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import("@/views/user/ForgotPassword"),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import("@/views/user/ResetPassword"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// EDIT AT YOUR OWN RISK
router.beforeEach((to, from, next) => {
    const checkOnboarding = () => {
        if (store.getters.user?.status && store.getters.user?.status.includes('onboarding') && to.name !== 'Onboarding') {
            next({name: 'Onboarding'})
        }
        next()
    }

    const checkIfAllowed = () => {
        if (store.getters.user?.status && store.getters.user?.status.includes('onboarding') && to.name !== 'VerifyEmail') {
            next({name: 'Onboarding'})
        }
        if (to.meta.allowed && !to.meta.allowed.includes(store.getters.user_type)) {
            next({name: 'Home'})
        }
        if (to.meta.permission && !store.getters.user?.permissions.includes(to.meta.permission)) {
            next({name: 'Home'})
        }
        next()
    }

    if (to.query.aff) {
        cookieService.setCookie('aff', to.query.aff, 365)
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.loggedIn) {
            if (store.getters.user === null) {
                store.dispatch('getUser').then(() => checkIfAllowed())
            } else {
                checkIfAllowed()
            }
        } else {
            next({name: 'Login'})
        }
    } else {
        if (store.getters.loggedIn) {
            if (store.getters.user === null) {
                store.dispatch('getUser').then(() => checkOnboarding())
            } else {
                checkOnboarding()
            }
        } else {
            next()
        }
    }
})

export default router
