
export const state = {
    users: [],
}

export const mutations = {
    setResponsibleUsers(state, users) {
        state.users = users
    },
}

export const actions = {

}

export const getters = {

}