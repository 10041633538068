import axios from '@/axios';

class ApplicationService {
    getVacancies(uriParams) {
        return axios.get(`/applications/vacancies`, {
            params: uriParams
        });
    }
    rejectionReasons() {
        return axios.get('/applications/reject/reasons');
    }
    getVacanciesByStatus(status, uriParams) {
        return axios.get(`/applications/vacancies/${status}`, {
            params: uriParams
        });
    }
    getVacancyCandidatesByStatus(vacancyId, status) {
        return axios.get(`/applications/vacancies/${vacancyId}/candidates/${status}`);
    }
    summary(uriParams){
        return axios.get(`/applications/summary`, {
            params: uriParams
        });
    }
    getApplication(id) {
        return axios.get(`/applications/${id}`);
    }
    approve(id) {
        return axios.get(`/applications/${id}/approve`);
    }
    reject(id, data = {}) {
        return axios.post(`/applications/${id}/reject`, data);
    }
}

export const applicationService = new ApplicationService();