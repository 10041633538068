import {createApp} from 'vue'
import App from './App.vue'
import vueMeta from './meta'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import clickOutside from "@/js/clickOutside";
import LoadScript from "vue-plugin-load-script";
import langList from "@/js/langList";
import countryList from "@/js/countryList";
import mitt from 'mitt';
/**
 * Emitter - to fire events between components AKA Event Bus
 */
const emitter = mitt();

const app = createApp(App)
    .directive("click-outside", clickOutside)
    .use(store)
    .use(router)
    .use(vueMeta, {
        refreshOnceOnNavigation:true,
        htmlAttrs: {
            lang: 'en',
        }
    })
    .use(LoadScript)
    .use(i18n)
    .mixin({
        data() {
            return {
                errors: [],
                disabled: false,
                emitter: emitter
            }
        },
        methods: {
            can(can) {
                return this.$store.getters.user?.permissions.includes(can)
            },
            languageName(code) {
                return langList.find(lang => lang.code === code)?.name || code
            },
            countryName(code) {
                return countryList.find(country => country.code === code)?.name || code
            },
            reload() {
                window.location = "/"
            }
        }
    })
    .mount('#app')
