<template>
    <div class="section section-get-started">
        <div class="container">
            <h3>{{ $t("Get Started Now") }}</h3>
            <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
            
            <slot name="action"></slot>
            
        </div>
    </div>
</template>