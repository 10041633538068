<template xmlns="http://www.w3.org/1999/html">
    <div class="contact-page">
        <div class="section contact-us-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="contact-us-header">
                            <h1>{{ $t('We\'d love to hear from You') }}</h1>
                            <p>{{ $t('If you have any questions, our staff will always be happy to help you.') }} {{ $t('Contact us by phone, email, or fill out the "Contact Us" form below, and we\'ll be sure to respond as soon as possible.') }}</p>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="contact-pilar-col">
                            <div class="contact-pilar" ref="pilar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48.981" height="30.244" viewBox="0 0 48.981 30.244">
                                    <g>
                                        <g>
                                            <path fill="#1f40e5" stroke="#1f40e5" stroke-width="5px" d="M32.553 24.957L12.235 45.275a1.563 1.563 0 0 1-2.2-2.2L29.24 23.847 10.016 4.639a1.563 1.563 0 0 1 2.2-2.2l20.322 20.314a1.563 1.563 0 0 1 .015 2.204zm-21.427 2.016a3.126 3.126 0 1 1 3.126-3.126 3.126 3.126 0 0 1-3.126 3.126zm0-3.126z" transform="translate(48.346 -5.5) rotate(90) translate(8 2.06) translate(-8 -2.06)"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="contact-information">
                            <div class="row">
                                <div class="col-6">
                                    <div class="contact-option">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                            <g>
                                                <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                            </g>
                                        </svg>
                                        <div class="info">
                                            <p>Vluchtoord 37, 5406 XP, Uden, {{ $t('Netherlands') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="contact-option">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.373" height="13.236" viewBox="0 0 19.373 13.236">
                                            <g>
                                                <path d="M24.12.089l7.692 6.564a1.443 1.443 0 0 0 1.932 0L41.436.089A1.532 1.532 0 0 0 40.92 0H24.636a1.534 1.534 0 0 0-.516.089z" transform="translate(0 0) translate(-23.092 0)"/>
                                                <path d="M0 30.416l6.751-6.161L.194 18.66a1.535 1.535 0 0 0-.194.749v11.007z" transform="translate(0 0) translate(0 -17.864)"/>
                                                <path d="M297.82 24.2l6.674 6.091V19.418a1.531 1.531 0 0 0-.194-.748z" transform="translate(0 0) translate(-285.121 -17.874)"/>
                                                <path d="M37.835 171.114l-6.8-6.2-.517.441a2.514 2.514 0 0 1-3.335 0l-.44-.375-6.725 6.138z" transform="translate(0 0) translate(-19.166 -157.878)"/>
                                            </g>
                                        </svg>
                                        <div class="info">
                                            <a href="mailto:info@eurecbroker.com">info@eurecbroker.com</a>
                                            <a href="mailto:support@eurecbroker.com">support@eurecbroker.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="contact-option">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.654" height="18.395" viewBox="0 0 17.654 18.395">
                                            <g>
                                                <path d="M1.917.654C2.176.5 2.43.337 2.7.212a2.344 2.344 0 0 1 2.858.735A2.924 2.924 0 0 1 6 1.986c.127.491.5 1.915.548 2.111a2.347 2.347 0 0 1-1.111 2.546 1.735 1.735 0 0 1-.572.237 1.46 1.46 0 0 0-.977 1.9 16.28 16.28 0 0 0 .874 1.748 16.151 16.151 0 0 0 1.029 1.567l.026.026a1.46 1.46 0 0 0 2.132.1 1.73 1.73 0 0 1 .49-.377 2.345 2.345 0 0 1 2.762.307c.148.137 1.193 1.174 1.555 1.529a2.912 2.912 0 0 1 .676.907 2.345 2.345 0 0 1-.791 2.84c-.244.173-.516.31-.775.459a3.79 3.79 0 0 1-3.89-.059 15.983 15.983 0 0 1-5.822-5.847A15.992 15.992 0 0 1 0 3.848 3.89 3.89 0 0 1 1.917.654zm14.539 6.2a1.2 1.2 0 1 1-1.2 1.2 1.2 1.2 0 0 1 1.2-1.2zm-3.719 0a1.2 1.2 0 1 1-1.2 1.2 1.2 1.2 0 0 1 1.2-1.2zm-3.719 0a1.2 1.2 0 1 1-1.2 1.2 1.2 1.2 0 0 1 1.2-1.2z" transform="translate(0 0) translate(0 0)"/>
                                            </g>
                                        </svg>
                                        <div class="info">
                                            <a href="tel:+31733030889">+31 73 30 30 889</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-form">
                                <h2>{{ $t('Contact Us') }}</h2>
                                <p>{{ $t('We\'re looking forward to hearing from you.') }}</p>
                                <form @submit.prevent="contactUs">
                                    <div class="form-group underlined-input">
                                        <input v-model="data.name" type="text" class="form-control-underlined" :placeholder="$t('Name')" required
                                        />
                                    </div>

                                    <FormErrors v-if="errors['name']" :errors="errors['name']"  />

                                    <div class="form-group underlined-input">
                                        <input v-model="data.email" type="email" class="form-control-underlined" :placeholder="$t('E-Mail')" required
                                        />
                                    </div>

                                    <FormErrors v-if="errors['email']" :errors="errors['email']"  />

                                    <div class="form-group underlined-input">
                                        <textarea v-model="data.message"
                                                  type="text"
                                                  class="form-control-underlined"
                                                  :placeholder="$t('Your Message')"
                                                  rows="1"
                                                  required
                                        />
                                    </div>

                                    <FormErrors v-if="errors['message']" :errors="errors['message']"  />

                                    <button type="submit" class="btn btn-primary btn-black" :disabled="cansend">{{ $t('Send') }}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import {contactUsService} from "@/services/contactUsService";
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: 'Contacts',
    data() {
        return {
            data: {},
        }
    },
    computed: {
        cansend() {
            return this.disabled || !(this.data.name && this.data.email && this.data.message)
        }
    },
    mounted () {
        useMeta({
            title: this.$t("Contact us"),
            description: this.$t(""),
        })
        window.addEventListener('scroll', (e) => {
            if ((document.body.scrollHeight - window.innerHeight) / 2 < window.scrollY) {
                this.$refs.pilar.classList.add('to-bottom');
            } else {
                this.$refs.pilar.classList.remove('to-bottom');
            }
        })
    },
    methods: {
        contactUs() {
            this.disabled = true
            contactUsService.contactUs(this.data)
                .then(() => alert('Done'))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    },
    components: {
        FormErrors
    }
}
</script>
