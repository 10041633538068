<template>
    <footer class="footer-area">
        <div class="footer-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-links">
                        <ul>
                            <li><router-link :to="{name: 'Home'}">{{ $t("Home") }}</router-link></li>
                            <li><router-link :to="{name: 'Terms'}">{{ $t("Terms of service") }}</router-link></li>
                            <li><router-link :to="{name: 'Privacy'}">{{ $t("Privacy policy") }}</router-link></li>
                            <li><router-link :to="{name: 'Contacts'}">{{ $t("Contacts") }}</router-link></li>
                        </ul>
                    </div>
                    <div class="col-xs-12 col-md-6 col-social">
                        <router-link to="#">
                            <img  :src="require('@/assets/img/linkedin.png')" alt="">
                        </router-link>
                        <router-link to="#">
                            <img  :src="require('@/assets/img/instagram.png')" alt="">
                        </router-link>
                        <router-link to="#">
                            <img  :src="require('@/assets/img/facebook.png')" alt="">
                        </router-link>

                        <span>{{ $t("© 2021  EuRecBroker") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>