import axios from "axios";
import store from "@/store";
import router from "@/router";

const instance = axios.create({})

instance.defaults.baseURL = process.env.VUE_APP_BASE_URL

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401)
    {
        store.dispatch('destroyToken')
        router.push('/login')
    }
    if (error.response.status === 403 && error.response.data.message)
    { // @todo maybe remove alert on production?
        alert(error.response.data.message)
    }
    return Promise.reject(error)
}, { synchronous: true });

instance.interceptors.request.use(function (config) {
    if (store.getters.locale !== null && store.getters.locale !== 'en')
    {
        config.headers['X-Locale'] = store.getters.locale;
    }
    return config;
}, null, { synchronous: true });


export default instance