import axios from '@/axios';

class CustomFieldService {
    getCustomFieldTypes() {
        return axios.get('/settings/customfields/customfieldtypes');
    }
    getCustomFieldInputTypes() {
        return axios.get('/settings/customfields/customfieldinputtypes');
    }
    get() {
        return axios.get('/settings/customfields');
    }
    add(data) {
        return axios.post('/settings/customfields', data);
    }
    update(id, data) {
        return axios.put(`/settings/customfields/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/settings/customfields/${id}`);
    }
}

export const customFieldService = new CustomFieldService();