import {companyService} from "@/services/companyService";

export const state = {
    companies: [],
    companyStatuses: null,
}

export const mutations = {
    setCompanies(state, companies) {
        state.companies = companies
    },
    addCompany(state, company) {
        state.companies.unshift(company)
    },
}

export const actions = {

}

export const getters = {
    companyStatuses(state) {
        if (!state.companyStatuses) {
            companyService.getCompanyStatuses().then(response => state.companyStatuses = response.data.data)
        }

        return state.companyStatuses
    },
}