<template>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container-fluid">
            <router-link :to="{name: 'Home'}" class="navbar-brand">
                <img :src="require('@/assets/img/logo.png')" alt="EURECBROKER">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleLanguageDropdown" v-click-outside="closeLanguageSelect">
                            {{ $i18n.locale.toUpperCase() }}
                        </a>
                        <ul :class="['dropdown-menu', showLanguages ? 'show' : '']" aria-labelledby="navbarDropdown">
                            <li v-for="lang in langList" :key="lang.name"><a class="dropdown-item" @click="changeLanguage(lang)">{{ lang.name }}</a></li>
                        </ul>
                    </li>
                    <HeaderSearch v-if="visibleSearch" />
                </ul>
                <ul :class="['navbar-nav mb-2 mb-lg-0', user ? '' : 'me-auto']">
                    <li class="nav-item" v-for="link in linksToShow" :key="link.text">
                        <router-link :to="{name: link.routeName}" class="nav-link">{{ $t(link.text) }}</router-link>
                    </li>
                </ul>


                <router-link :to="{name: 'Register'}" class="btn btn-primary btn-sign-up" type="button" v-if="!user">{{ $t("Sign up") }}</router-link>

                <Notifications v-if="user" />

                <router-link :to="{name: 'Register'}" class="btn btn-secondary icon-btn" type="button" v-if="!user">
                    <img :src="require('@/assets/user-icon.svg')">
                </router-link>

                <ul class="navbar-nav" v-if="user">
                    <li class="nav-item dropdown">
                        <div class="user-menu-button" @click="toggleSettingsDropdown" v-click-outside="closeSettingsDropdown">
                            <img class="img" :src="user.image" />
                            <div class="btn-stripes">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <ul :class="['dropdown-menu', showSettings ? 'show' : '']" aria-labelledby="navbarDropdownSettings">
                            <li v-for="link in settingsLinksToShow" :key="link.text">
                                <router-link :to="{name: link.routeName}" class="dropdown-item">{{ $t(link.text) }}</router-link>
                            </li>
                            <li>
                                <router-link to="#" @click="logout" class="dropdown-item">{{ $t("Logout") }}</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>


            </div>
        </div>
    </nav>
</template>

<script>
import links from "./links"
import settingsLinks from "./settingsLinks"
import langList from "@/js/langList";
import {i18n, setI18nLanguage} from "@/i18n";
import HeaderSearch from '@/navigation/HeaderSearch';
import Notifications from '@/components/widgets/Notifications';

export default {
    name: "Navigation",
    components: {
        HeaderSearch,
        Notifications
    },
    data() {
        return {
            showLanguages: false,
            showSettings: false,
            linksToShow: {},
            settingsLinksToShow: {},
            visibleSearch: false
        }
    },
    mounted() {
        this.checkLinks();
        
        /**
         * Add Event Listeners for "Event Bus" to listen whether to show or hide header search
        */
        this.emitter.on("show-header-search", this.showSearch);
        this.emitter.on("hide-header-search", this.hideSearch);
    },
    unmounted() {
        /**
         * Removes event listeners
        */
        this.emitter.off("show-header-search", this.showSearch);
        this.emitter.off("hide-header-search", this.hideSearch);
    },
    watch:{
        $route (to, from){
            this.checkLinks();
        },
        user_type (to, from){
            this.checkLinks();
        }
    },
    computed: {
        langList() {
            return langList;
        },
        links() {
            return links;
        },
        settingsLinks() {
            return settingsLinks;
        },
        user_type() {
            return this.$store.getters.user_type
        },
        user() {
            return this.$store.getters.user
        }
    },
    methods: {
        checkLinks() {
            this.linksToShow = this.links.filter(link => this.checkIfLinkIsAllowed(link.allowed))
            this.settingsLinksToShow = this.settingsLinks.filter(link => this.checkIfLinkIsAllowed(link.allowed))
        },
        closeLanguageSelect() {
            this.showLanguages = false;
        },
        toggleLanguageDropdown() {
            if (this.showLanguages) {
                this.showLanguages = false
            } else {
                this.showLanguages = true
            }
        },
        closeSettingsDropdown() {
            this.showSettings = false;
        },
        toggleSettingsDropdown() {
            if (this.showSettings) {
                this.showSettings = false
            } else {
                this.showSettings = true
            }
        },
        changeLanguage(lang) {
            this.showLanguages = false
            setI18nLanguage(i18n, lang.code.toLowerCase())
        },
        logout() {
            this.$store.dispatch('logout');
            this.reload()
        },
        checkIfLinkIsAllowed(allowedList) {

            if (typeof allowedList === 'function') {
                return allowedList();
            }

            if (allowedList.length === 0) {
                return true
            }

            return allowedList.includes(this.user_type);
        },
        showSearch() {
            this.visibleSearch = true;
        },
        hideSearch() {
            this.visibleSearch = false;
        }
    }
}
</script>