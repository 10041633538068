import {myVacancyService} from "@/services/myVacancyService";
import groupBy from "@/js/groupBy";

export const state = {
    vacancies: [],
    myVacancies: [],
    formData: [],
    customFields: null,
    companyCustomFields: [],
}

export const mutations = {
    setVacancies(state, vacancies) {
        state.vacancies = vacancies
    },
    deleteVacancy(state, vacancy) {
        const index = state.vacancies.indexOf(vacancy)
        if (index >= 0) state.vacancies.splice(index, 1)
    },
    setMyVacancies(state, myVacancies) {
        state.myVacancies = myVacancies
    },
    deleteMyVacancy(state, vacancy) {
        const index = state.myVacancies.indexOf(vacancy)
        if (index >= 0) state.myVacancies.splice(index, 1)
    },
    setVacanciesCustomFields(state, customFields) {
        state.customFields = customFields
    },
    setVacanciesCompanies(state, companies) {
        state.companies = companies
    },
    setVacanciesCompanyCustomFields(state, customFields) {
        state.companyCustomFields = customFields
    },
}

export const actions = {

}

export const getters = {
    vacancyCustomFields(state) {
        if (!state.customFields) {
            myVacancyService.getVacancyCustomFields()
                .then(response => {
                    const groupByType = groupBy('selectedType')
                    state.customFields = groupByType(response.data.data)
            })
        }
        return state.customFields
    },
    companyCustomFields: (state) => (id) => {
        if (!state.companyCustomFields[id]) {
            myVacancyService.companyCustomFields(id)
                .then(response => {
                    const groupByType = groupBy('selectedType')
                    state.companyCustomFields[id] = groupByType(response.data.data)
                })
        }
        return state.companyCustomFields[id]
    }
}