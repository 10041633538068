import axios from '@/axios';

class UserTypeService {
    getUserTypes() {
        return axios.get('/settings/usertypes');
    }
    addUserType(data) {
        return axios.post('/settings/usertypes', data);
    }
    getPermissionTitles() {
        return axios.get('/settings/usertypes/permissiontitles');
    }
    getMaxPermissions() {
        return axios.get('/settings/usertypes/maxpermissions');
    }
    getUserTypePermissions(id) {
        return axios.get(`/settings/usertypes/${id}/permissions`);
    }
    updateUserTypePermissions(id, data) {
        return axios.post(`/settings/usertypes/${id}/permissions`, data);
    }
    deleteUserType(id) {
        return axios.delete(`/settings/usertypes/${id}`);
    }
}

export const userTypeService = new UserTypeService();