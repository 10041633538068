import { createStore } from 'vuex'
import modules from './modules'

const store = createStore({
  modules,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
})

export default store