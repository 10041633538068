
export const state = {
    candidates: null,
}

export const mutations = {
    setCandidates(state, candidates) {
        state.candidates = candidates
    },
    addCandidate(state, candidate) {
        state.candidates.unshift(candidate)
    },
}

export const actions = {

}

export const getters = {

}