import axios from '@/axios';

class IndustriesService {
    vacancies() {
        return axios.get(`/industries/vacancies`);
    }

    candidates() {
        return axios.get(`/industries/candidates`);
    }
}

export default new IndustriesService();