import axios from '@/axios';

class MyVacanciesService {
    index() {
        return axios.get('/my/vacancies');
    }
    addVacancy(formData) {
        return axios.post('/my/vacancies', formData);
    }
    getVacancy(id) {
        return axios.get(`/my/vacancies/${id}`);
    }
    updateVacancy(id, formData) {
        return axios.post(`/my/vacancies/${id}`, formData);
    }
    deleteVacancy(id) {
        return axios.delete(`/my/vacancies/${id}`);
    }
    changeStatus(id, data) {
        return axios.patch(`/my/vacancies/${id}`, data);
    }
    getVacancyCustomFields() {
        return axios.get('/my/vacancies/customfields');
    }
    vacancyCustomFields(vacancyId) {
        return axios.get(`/my/vacancies/${vacancyId}/customfields`);
    }
    getCandidates(id, status = null) {
        if (status != null) {
            return axios.get(`/my/vacancies/${id}/candidates/${status}`);
        }
        return axios.get(`/my/vacancies/${id}/candidates`);
    }
    getCompanies() {
        return axios.get(`/my/vacancies/companies`);
    }
    companyCustomFields(companyId) {
        return axios.get(`/companies/${companyId}/customfields`);
    }
    getVacancyPriceRate(vacancyId) {
        return axios.get(`/my/vacancies/${vacancyId}/price_rate`);
    }
    setVacancyPriceRate(vacancyId, data) {
        return axios.post(`/my/vacancies/${vacancyId}/price_rate`, data);
    }
}

export const myVacancyService = new MyVacanciesService();