import {invoiceService} from "@/services/invoiceService";

export const state = {
    invoices: [],
    invoiceStatuses: null,
    email: {},
}

export const mutations = {
    setInvoices(state, invoices) {
        state.invoices = invoices
    }
}

export const actions = {

}

export const getters = {
    invoiceStatuses(state) {
        if (!state.invoiceStatuses) {
            invoiceService.getInvoiceStatuses().then(response => state.invoiceStatuses = response.data.data)
        }
        return state.invoiceStatuses
    },
    invoiceEmail(state) {
        if (! (state.email.subject || state.email.message)) {
            invoiceService.getInvoiceEmail().then(response => state.email = response.data)
        }
        return state.email
    }
}