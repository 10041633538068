import axios from '@/axios';

class UserService {
    login(data) {
        return axios.post(`/login`, data);
    }
    socialAuthRedirect(network) {
        return axios.get(`/auth/redirect/`+network, {}, {
            headers: {
                // remove headers
            }
        });
    }
    socialAuthCallback(network, params) {
        return axios.get(`/auth/callback/`+network, {params: params, withCredentials: true});
    }
    verify(uriParams) {
        return axios.get(`/verify/email/${uriParams.id}/${uriParams.hash}`);
    }
    logout() {
        return axios.post(`/logout`);
    }
    getUser() {
        return axios.get(`/user`);
    }
    register(data, uriParams) {
        const reducer = (accumulator, currentValue) => accumulator += currentValue.join('=') + '&';

        let url = '/register' + Object.entries(uriParams).reduce(reducer, '?').slice(0, -1)
        return axios.post(url, data, {withCredentials: true});
    }
    getProfile() {
        return axios.get('/user/profile')
    }
    updateProfile(formData) {
        return axios.post('/user/profile', formData)
    }
    getCompany() {
        return axios.get('/user/company')
    }
    updateCompany(formData) {
        return axios.post('/user/company', formData)
    }
    updateUserAbout(data) {
        return axios.post('/user/about', data)
    }
    patchUserAbout(data) {
        return axios.patch('/user/about', data)
    }
    updateProfileImage(formData) {
        return axios.post('/user/profile/image', formData)
    }
    forgotPassword(data) {
        return axios.post('/forgot-password', data)
    }
    resetPassword(data) {
        return axios.post('/reset-password', data)
    }
}

export const userService = new UserService();