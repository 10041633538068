<template>
    <div class="section section-items">
        <div class="container">
            <div class="row section-items-row">
                <div class="col-xs-12 col-md-6">
                    <h2>{{ $t("Our Talents") }}</h2>
                </div>
                <div class="col-xs-12 col-md-6">
                    <p class="text-md-end" v-html='this.$t("More Than <span>{count}</span> Talents", {count: candidatesCount})'></p>
                </div>
            </div>
        </div>

        <div class="rec-container">
            <carousel :items-to-show="4.5" snap-align="start" wrap-around="true">
                <slide v-for="candidate in candidates" :key="candidate.id">
                    <div class="slide-image">
                        <img :src="candidate.image" :alt="candidate.name">
                    </div>
                    <div class="position-relative slide-details">
                        <router-link to="#">{{ candidate.name }}</router-link>
                        <span class="badge bg-secondary">{{ candidate.candidates_count }}</span>
                    </div>
                </slide>
            </carousel>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-12 text-md-center">
                    <router-link to="#" class="btn btn-secondary">{{ $t("All Categories") }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import IndustriesService from '../../services/industriesService';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    mounted() {
        this.getCandidates();
    },
    methods: {
        async getCandidates() {
            await IndustriesService.candidates().then(res => {
                const { candidates_count, data } = res.data;
                
                if (candidates_count) {
                    this.$store.dispatch('addCandidatesCount', candidates_count);
                }

                if (data) {
                    this.$store.dispatch('addCandidates', data);
                }

            }).catch(err => {
                console.log(err);
            })
        }
    },
    computed: {
        candidates() {
            return this.$store.getters.candidates;
        },
        candidatesCount() {
            return this.$store.getters.candidatesCount;
        }
    }
}
</script>