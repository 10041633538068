<template>
    <div :class="classList">
        <metainfo>
            <template v-slot:title="{ content }">{{ content }} - EURECBROKER.COM</template>
            <template v-slot:description="{ content }">{{ content }} - EURECBROKER.COM</template>
            <template v-slot:bodyAttrs="classList">{{ classList }}</template>
        </metainfo>

        <Navigation></Navigation>

        <router-view></router-view>

        <ContactButton />

        <Footer></Footer>
    </div>
</template>

<script>
import Navigation from "@/navigation/Navigation";
import Footer from "@/page/Footer";
import ContactButton from './components/widgets/ContactButton';

export default {
    name: 'App',
    components: {
        Navigation,
        Footer,
        ContactButton
    },
    computed: {
        logedIn() {
            return this.$store.getters.loggedIn;
        },
        classList() {
            const list = ['body-wrapper'];
            
            if (this.logedIn) {
                list.push('is-logged-in');
            }

            return list.join(' ');
        }
    }
}
</script>

<style lang="scss">
@import "styles/main";
</style>
