import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import {translationService} from '@/services/translationService'

export const SUPPORT_LOCALES = ['en', 'ja']

export function setupI18n(options = { locale: 'en' }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)

  loadLocaleMessages(i18n, locale)
}

export async function loadLocaleMessages(i18n, locale) {
  translationService.index(locale).then(response => {
    console.log(response.data);
    console.log(locale);
    i18n.global.setLocaleMessage(locale, response.data)
  });

  return nextTick()
}

export const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {}
});
