
import axios from "@/axios";

export const state = {
    employmentTypes: null
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    employmentTypes(state) {
        if (!state.employmentTypes) {
            axios.get(`/employmenttypes`).then(response => state.employmentTypes = response.data.data)
        }
        return state.employmentTypes
    }
}