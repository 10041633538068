
import axios from "@/axios";

export const state = {
    tags: null,
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    tags(state) {
        if (!state.tags) {
            axios.get(`/tags`).then(response => state.tags = response.data.data)
        }
        return state.tags
    }
}