import { applicationService } from "@/services/applicationService";

export const state = {
    vacancies: [],
    reasons: [],
}

export const mutations = {
    setApplicationsVacancies(state, vacancies) {
        state.vacancies = vacancies
    },
    setRejectReasons(state, reasons) {
        state.reasons = reasons
    }
}

export const actions = {

}

export const getters = {
    reasons(state) {
        if (!state.reasons.length) {
            applicationService.rejectionReasons().then(response => state.reasons = response.data.data);
        }
        return state.reasons
    }
}