<template>
    <div class="form-error" v-for="(error, index) in errors" :key="index">
        {{ error }}
    </div>
</template>

<script>
export default {
    props: [ 'errors' ]
}
</script>