
export const state = {
    vacanciesCount: 0,
    candidatesCount: 0,
    vacancies: [],
    candidates: []
}

export const mutations = {
    setVacancies(state, payload) {
        state.vacancies = payload;
    },
    setVacanciesCount(state, payload) {
        state.vacanciesCount = payload;
    },
    setCandidates(state, payload) {
        state.candidates = payload;
    },
    setCandidatesCount(state, payload) {
        state.candidatesCount = payload;
    }
}

export const actions = {
    addVacancies({ commit }, payload) {
        commit('setVacancies', payload);
    },
    addVacanciesCount({ commit }, payload) {
        commit('setVacanciesCount', payload);
    },
    addCandidates({ commit }, payload) {
        commit('setCandidates', payload);
    },
    addCandidatesCount({ commit }, payload) {
        commit('setCandidatesCount', payload);
    }
}

export const getters = {
    vacancies(state) {
        return state.vacancies;
    },
    vacanciesCount(state) {
        return state.vacanciesCount;
    },
    candidates(state) {
        return state.candidates;
    },
    candidatesCount(state) {
        return state.candidatesCount;
    }
}