<template>
    <h1 class="text-center p-5">{{ $t("Page not found") }}</h1>
</template>

<script>
export default {
    name: "NotFound",
    // created() {
    //   console.log(this.$store.getters.user_type)
    // }
}
</script>
