import {customFieldService} from "@/services/settings/customFieldService";

export const state = {
    customFields: [],
    customFieldTypes: null,
    customFieldInputTypes: null
}

export const mutations = {
    setCustomFields(state, customFields) {
        state.customFields = customFields
    },
    setCustomFieldTypes(state, customFieldTypes) {
        state.customFieldTypes = customFieldTypes
    },
    setCustomFieldInputTypes(state, customFieldInputTypes) {
        state.customFieldInputTypes = customFieldInputTypes
    },
    deleteCustomField(state, customField) {
        const index = state.customFields[customField.selectedType].indexOf(customField)
        if (index >= 0) state.customFields[customField.selectedType].splice(index, 1)
    },
    addCustomField(state, customField) {
        state.customFields[customField.selectedType]
        ? state.customFields[customField.selectedType].unshift(customField)
        : state.customFields[customField.selectedType] = [customField]
    }
}

export const actions = {

}

export const getters = {
    customFieldTypes(state) {
        if (!state.customFieldTypes) {
            customFieldService.getCustomFieldTypes().then(response => state.customFieldTypes = response.data.data)
        }
        return state.customFieldTypes
    },
    customFieldInputTypes(state) {
        if (!state.customFieldInputTypes) {
            customFieldService.getCustomFieldInputTypes().then(response => state.customFieldInputTypes = response.data.data)
        }
        return state.customFieldInputTypes
    }

}