import axios from '@/axios';

class UserGroupService {
    getUserGroups() {
        return axios.get('/settings/usergroups');
    }
    addUserGroup(data) {
        return axios.post('/settings/usergroups', data);
    }
    getPermissionTitles() {
        return axios.get('/settings/usergroups/permissiontitles');
    }
    getMaxPermissions() {
        return axios.get('/settings/usergroups/maxpermissions');
    }
    getUserGroupPermissions(id) {
        return axios.get(`/settings/usergroups/${id}/permissions`);
    }
    updateUserGroupPermissions(id, data) {
        return axios.post(`/settings/usergroups/${id}/permissions`, data);
    }
    deleteUserGroup(id) {
        return axios.delete(`/settings/usergroups/${id}`);
    }
}

export const userGroupService = new UserGroupService();