<template>
    <div class="pricing-page">
        <div class="section pricing-page-section">
            <div class="container-wide">
                <div class="row">
                    <div class="col-5">
                        <div class="pricing-intro">
                            <h1>{{ $t("Our Pricings and Plans") }}</h1>
                            <p>{{ $t("Our plans are based within two categories, plan one offers more simplier talent finds, our second plan is for more compelx and highly skilled talents as developers, etc.") }}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.216" height="30.184" viewBox="0 0 17.216 30.184">
                                <g>
                                    <g>
                                        <path stroke="#000" stroke-width="2px" d="M24.353 16.763L11.307 29.809a1 1 0 0 1-1.415-1.415L22.226 16.05 9.882 3.716A1 1 0 0 1 11.3 2.3l13.043 13.048a1 1 0 0 1 .01 1.415z" transform="translate(-8.544 -0.963) translate(9.64 2.06) translate(-9.64 -2.06)"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="row pricing-plan-row">
                            <div class="col-6">
                                <div class="pricing-plan">
                                    <p class="price">€ 159</p>
                                    <h2>{{ $t('Standard') }}</h2>
                                    <p>{{ $t('Better for simplier Works such as home cleaning and taxi drivers') }}</p>
                                    <ul>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited access')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited free application view')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited send offers to candidate')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Company profile')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Visible company details')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Dedicated recruiter')}}
                                        </li>
                                    </ul>
                                    <button class="btn btn-secondary">{{ $t('Get Standard') }}</button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="pricing-plan">
                                    <p class="price">€ 359</p>
                                    <h2>{{ $t('Advanced') }}</h2>
                                    <p>{{ $t('Better for simplier Works such as home cleaning and taxi drivers') }}</p>
                                    <ul>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited access')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited free application view')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Unlimited send offers to candidate')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Company profile')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Visible company details')}}
                                        </li>
                                        <li>
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                                    <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                                                </svg>
                                            </i>
                                            {{ $t('Dedicated recruiter')}}
                                        </li>
                                    </ul>
                                    <button class="btn btn-secondary">{{ $t('Get Advanced') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { useMeta } from 'vue-meta'

export default {
    name: 'Pricing',
    mounted () {
        useMeta({
            title: this.$t("Pricing"),
            description: this.$t(""),
        })
    }

}
</script>
