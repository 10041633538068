
import axios from "@/axios";

export const state = {
    languages: null,
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    languages(state) {
        if (!state.languages) {
            axios.get(`/languages`).then(response => state.languages = response.data.data)
        }
        return state.languages
    }
}