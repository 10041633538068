const links = [

    {text: 'For talent', routeName: 'Home', allowed: function () {
        return !localStorage.getItem("access_token");
    }},
    {text: 'For companies', routeName: 'ForCompanies', allowed: function () {
        return !localStorage.getItem("access_token");
    }},
    {text: 'Vacancies', routeName: 'Vacancies', allowed: function () {
        return window.location.pathname !== "/for-companies" && !localStorage.getItem("access_token");
    }},
    {text: 'Candidates', routeName: 'Candidates', allowed: function () {
        return window.location.pathname === "/for-companies";
    }},
    {text: 'Inquiries', routeName: 'Contacts', allowed: function () {
        return !localStorage.getItem("access_token");
    }},

    {text: 'Dashboard', routeName: 'Home', allowed: ['Management', 'Client', 'Candidate']},
    {text: 'Candidates', routeName: 'Candidates', allowed: ['Management', 'Client']},
    {text: 'Vacancies', routeName: 'Vacancies', allowed: ['Management', 'Client', 'Candidate']},
    {text: 'Invoices', routeName: 'Invoices', allowed: ['Management', 'Client']},
    {text: 'Applications', routeName: 'Applications', allowed: ['Candidate', 'Client', 'Management']},

]

export default links