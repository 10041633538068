
export const state = {
    locale: localStorage.getItem('locale'),
}

export const mutations = {
    setLocale(state, locale) {
        state.locale = locale
    },
    destroyLocale(state) {
        state.locale = null
    }
}

export const actions = {
    setLocale({commit}, locale) {
        commit('setLocale', locale)
        localStorage.setItem('locale', locale)
    },
}

export const getters = {
    locale(state) {
        return state.locale
    },
}