
import axios from "@/axios";

export const state = {
    countries: null,
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    countries(state) {
        if (!state.countries) {
            axios.get(`/countries`).then(response => state.countries = response.data.data)
        }
        return state.countries
    }
}