<template>
    <div class="privacy-page">
        <div class="section privacy-policy-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ $t("Privacy Policy") }}</h1>
                        <p>{{ $t("This Privacy Policy regulates what personal data we collect from you and how we use it.") }}</p>
                    </div>
                    <div class="col-12">
                        <div class="privacy-list">
                            <div :class="['list-item-wrapper', {'selected': isVisible(index)}]" v-for="(privacy, index) in privacies" :key="index">
                                <div class="list-item" @click="showMore(index)">
                                    <p><b>{{ index + 1 }}:</b>{{ privacy.intro }}</p>
                                    <svg class="icon viewable" xmlns="http://www.w3.org/2000/svg" width="15.807" height="8.865" viewBox="0 0 15.807 8.865">
                                        <g>
                                            <g>
                                                <g>
                                                    <path d="M238.206 36.726l.692-.692 1.657-1.657 2-2 1.731-1.731c.28-.28.564-.559.842-.842l.011-.011a.982.982 0 0 0 0-1.367l-.692-.692-1.657-1.657-2-2-1.731-1.731c-.28-.28-.559-.564-.842-.842l-.011-.011a1 1 0 0 0-.683-.283.968.968 0 0 0-.966.966 1 1 0 0 0 .283.683l.692.692 1.658 1.649 2 2 1.731 1.731c.28.28.557.565.842.842l.011.011v-1.365l-.692.692-1.657 1.657-2 2-1.731 1.732c-.28.28-.564.559-.842.842l-.011.011a1 1 0 0 0-.283.683.968.968 0 0 0 1.649.686z" transform="translate(37.007 -236.556) rotate(90) translate(236.556 21.2) translate(0 0) translate(-236.556 -21.2)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <transition name="slide-down">
                                    <div class="more-privacy" v-if="isVisible(index)">
                                        <p>{{ privacy.more }}</p>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section section section-divider"></div>

        <GetStarted>
            <template v-slot:action>
                <router-link :to="{name: 'Register'}" class="btn btn-primary">{{ $t("Register Now") }}</router-link>
            </template>
        </GetStarted>
    </div>

</template>

<script>
import { useMeta } from 'vue-meta'
import GetStarted from '@/components/home/GetStarted';

export default {
    name: 'Contacts',
    data() {
        return {
            openedPrivacies: [],
            privacies: [
                {
                    intro: this.$t('When you use our services, we collect and gather various information including your personal data. In particular, we collect information from:'),
                    more: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                    intro: this.$t('The controller of your personal data processed by us is ZUTZU NL BV (78346150) based in Netherlands, Vluchtoord 37, Uden. We acquire the above information primarily to provide services consisting of finding the right people for the right job, and vice versa, in particular to:'),
                    more: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                    intro: this.$t('When you use our services, we collect and gather various information including your personal data. In particular, we collect information from:'),
                    more: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                    intro: this.$t('When you use our services, we collect and gather various information including your personal data. In particular, we collect information from:'),
                    more: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                    intro: this.$t('When you use our services, we collect and gather various information including your personal data. In particular, we collect information from:'),
                    more: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                }
            ]
        }
    },
    methods: {
        showMore(index) {
            const openedIndex = this.openedPrivacies.indexOf(index);
            if (openedIndex == -1) {
                this.openedPrivacies.push(index);
            } else {
                this.openedPrivacies.splice(openedIndex, 1);
            }
        },
        isVisible(index) {
            return this.openedPrivacies.indexOf(index) != -1 ? true : false;
        }
    },
    components: {
        GetStarted
    },
    mounted () {
        useMeta({
            title: this.$t("Privacy policy"),
            description: this.$t(""),
        })
    }

}
</script>
