<template>
    <div class="user-notification-widget">
        <div class="notification-toggler">
            <div class="notification-button" @click="showListing = !showListing" v-click-outside="closeListing">
                <span>10</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <g>
                        <g>
                            <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
        <transition name="fade">
            <div class="notification-list" v-if="showListing">
                <div class="notification-item">
                    <span>Title</span>
                    <span>Body</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showListing: false,
        }
    },
    methods: {
        closeListing() {
            this.showListing = false;
        },

    }
}
</script>