<template>
    <li class="header-search-input">
        <div class="form-group icon-input">
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20.155" height="20.176" viewBox="0 0 20.155 20.176">
                <path d="M30.576 29.224l-3.73-3.73a8.9 8.9 0 1 0-1.427 1.452l3.73 3.73a.988.988 0 0 0 .7.3.909.909 0 0 0 .7-.3 1.052 1.052 0 0 0 .027-1.452zm-3.755-9.387a7.008 7.008 0 1 1-2.053-4.982 7.005 7.005 0 0 1 2.053 4.982z" transform="translate(-10.7 -10.8)"/>
            </svg>
            <input type="text" class="form-control" v-model="query" @input="debounceGetEvents" :placeholder="$t('Search')">
        </div>
    </li>
</template>

<script>
import _ from "lodash";

export default {
    data() {
        return {
            query: ''
        }
    },
    methods: {
        clearInput() {
            this.query = '';
        },
        debounceGetEvents: _.debounce(function () {
            this.emitter.emit("search-from-header", this.query);
        }, 1000)
    },
    mounted() {
        /**
         * Whenever hide header search event is fired, reset query
         */
        this.emitter.on("hide-header-search", this.clearInput);
    },
    unmounted() {
        /**
         * Stop listening for event, when component is destroyed
         */
        this.emitter.off("hide-header-search", this.clearInput);
    }
}
</script>