<template>

    <div v-if="!$store.getters.loggedIn">

        <div class="section section-header">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-7">
                        <div class="content-wrapper">
                            <h1>{{ $t("Find Great Talent in RecBroker") }}</h1>
                            <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
                            <router-link to="#" class="btn btn-secondary">{{ $t("Get started") }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="bg-cover for-companies"></div>
                <div class="circle black"></div>
                <div class="circle blue"></div>
            </div>
        </div>

        <HomeCandidateIndustries />

        <div class="section section-how-it-works">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <h2>{{ $t("How it works?") }}</h2>
                        <div class="row how-row">
                            <div class="col-xs-12 col-md-6 how-column left">
                                <span>01.</span>
                                <h3>{{ $t("Register and create Vacancy") }}</h3>
                                <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
                            </div>
                            <div class="col-xs-12 col-md-6 how-image right">
                                <img  :src="require('@/assets/img/candidate-mobile-1.png')" alt="">
                            </div>
                        </div>
                        <div class="row how-row">
                            <div class="col-xs-12 col-md-6 how-image left">
                                <img :src="require('@/assets/img/candidate-mobile-2.png')" alt="">
                            </div>
                            <div class="col-xs-12 col-md-6 how-column right">
                                <span>02.</span>
                                <h3>{{ $t("Be notified about new vacancies via dashboard") }}</h3>
                                <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
                            </div>
                        </div>
                        <div class="row how-row">
                            <div class="col-xs-12 col-md-6 how-column left">
                                <span>03.</span>
                                <h3>{{ $t("Pay only for chosen candidates") }}</h3>
                                <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
                            </div>
                            <div class="col-xs-12 col-md-6 how-image right">
                                <img :src="require('@/assets/img/candidate-mobile-confetti.png')" alt="" class="confetti">
                                <img :src="require('@/assets/img/company-mobile-3.png')" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-cta">
            <div class="rec-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-md-5">
                            <h3>{{ $t("Find the best people for your Company") }}</h3>
                            <p>{{ $t("We will help you to find your dream job by bringing you relevant job offers. And there’s much more to it!") }}</p>
                            <router-link to="#" class="btn btn-primary">{{ $t("Get started") }}</router-link>
                        </div>
                        <div class="col-xs-12 col-md-7">
                            <div class="image-wrapper">
                                <img :src="require('@/assets/img/company-cta-1.png')" alt="" class="first">
                                <img :src="require('@/assets/img/company-cta-2.png')" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-testimonials">
            <div class="rec-container">
                <carousel :items-to-show="1">
                    <slide v-for="slide in 3" :key="slide">
                        <div class="testimonial">
                            <h3>"{{ $t("Just Excellent") }}"</h3>
                            <p>"{{ $t("I can only recomend RecBroker they did an awesome work for our company, we did find a great team in a very short period of time, thank you again RecBroker team, we are really happy about the outcome and our new workers see you again, hehe!") }}"</p>
                        </div>
                    </slide>
                    <template #addons="{ currentSlide }">
                        <div class="testimonial-persons">
                            <div v-for="slide in 3" :key="slide" :class="['testimonial-person', slide==(currentSlide+1) ? 'active' : '']">
                                <img :src="require('@/assets/img/testimonials-1.png')" alt="">
                                <p class="name">Thomas Grey</p>
                                <p class="company">Remedy Enterprises</p>
                            </div>
                        </div>
                        <navigation />
                    </template>
                </carousel>
            </div>
        </div>

        <div class="section section-divider"></div>

        <GetStarted>
            <template v-slot:action>
                <router-link to="#" class="btn btn-primary">{{ $t("Register Now") }}</router-link>
            </template>
        </GetStarted>

    </div>

    <ContactButton />

    <component :is="currentComponent"></component>

</template>

<script>
// import CandidateDashboard from "@/views/dashboards/CandidateDashboard";
// import ClientDashboard from "@/views/dashboards/ClientDashboard";
// import ManagmentDashboard from "@/views/dashboards/ManagmentDashboard";
import {defineAsyncComponent} from "vue";

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {useMeta} from "vue-meta";
import { setI18nLanguage, i18n } from "@/i18n";
import ContactButton from '@/components/widgets/ContactButton';

import HomeCandidateIndustries from '@/components/home/HomeCandidateIndustries';
import GetStarted from '@/components/home/GetStarted';

export default {
    name: 'Home',
    // created() {
    //   console.log(this.$store)
    // },
    computed: {
        currentComponent() {
            return this.$store.getters.user_type + 'Dashboard'
        }
    },
    data() {
        return {
            "testimonialsSlide": 0
        }
    },
    components: {
        CandidateDashboard: defineAsyncComponent(() =>
            import("@/views/dashboards/CandidateDashboard")
        ),
        ClientDashboard: defineAsyncComponent(() =>
            import("@/views/dashboards/ClientDashboard")
        ),
        ManagementDashboard: defineAsyncComponent(() =>
            import("@/views/dashboards/ManagementDashboard")
        ),
        SuperAdminDashboard: defineAsyncComponent(() =>
            import("@/views/dashboards/SuperAdminDashboard")
        ),
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ContactButton,
        HomeCandidateIndustries,
        GetStarted
    },
    mounted () {
        useMeta({
            title: this.$t("For companies"),
            description: this.$t(""),
        })
    }
}
</script>
