
import axios from "@/axios";

export const state = {
    industries: null,
}

export const mutations = {

}

export const actions = {

}

export const getters = {
    industries(state) {
        if (!state.industries) {
            axios.get('/industries').then(response => state.industries = response.data.data)
        }
        return state.industries
    }
}