import axios from '@/axios';

class CompanyService {
    getCompanies(uriParams) {
        return axios.get('/companies', {
            params: uriParams
        })
    }
    getCompany(companyId) {
        return axios.get(`/companies/${companyId}`)
    }
    deleteCompany(companyId) {
        return axios.delete(`/companies/${companyId}`)
    }
    addCompany(data) {
        return axios.post('/companies', data)
    }
    getCompanyDetails(id) {
        return axios.get(`/companies/${id}/company_details`)
    }
    getCompanyStatuses() {
        return axios.get('/company/statuses')
    }
    updateCompanyStatus(companyId, statusId) {
        return axios.get(`/companies/${companyId}/status/${statusId}`)
    }
}

export const companyService = new CompanyService();