import axios from '@/axios';

class InvoiceService {
    getInvoices() {
        return axios.get('/invoices')
    }
    getInvoice(invoiceId) {
        return axios.get(`/invoices/${invoiceId}`)
    }
    createInvoice(data) {
        return axios.post('/invoices', data)
    }
    updateInvoice(invoiceId, data) {
        return axios.put(`/invoices/${invoiceId}`, data)
    }
    getInvoiceStatuses() {
        return axios.get('/invoice/statuses')
    }
    updateInvoiceStatus(invoiceId, statusId) {
        return axios.get(`/invoices/${invoiceId}/status/${statusId}`)
    }
    deleteInvoice(invoiceId) {
        return axios.delete(`/invoices/${invoiceId}`)
    }
    restoreInvoice(invoiceId) {
        return axios.get(`/invoices/${invoiceId}/restore`)
    }
    getInvoiceEmail() {
        return axios.get('/invoice/email')
    }
    sendInvoice(invoiceId, data) {
        return axios.post(`/invoices/${invoiceId}/send`, data)
    }
}

export const invoiceService = new InvoiceService()