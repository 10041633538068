<template>
    <div class="section section-items">
        <div class="container">
            <div class="row section-items-row">
                <div class="col-xs-12 col-md-6">
                    <h2>{{ $t("Vacancies") }}</h2>
                </div>
                <div class="col-xs-12 col-md-6">
                    <p class="text-md-end" v-html="this.$t('We offer more then <span>{count}</span> vacancies', { count: vacancyCount })"></p>
                </div>
            </div>
        </div>

        <div class="rec-container">
            <carousel :items-to-show="4.5" snap-align="start" wrap-around="true">
                <slide v-for="vacancy in vacancies" :key="vacancy.id">
                    <div class="slide-image">
                        <img :src="vacancy.image" :alt="vacancy.name">
                    </div>
                    <div class="position-relative slide-details">
                        <router-link to="#">{{ vacancy.name }}</router-link>
                        <span class="badge bg-secondary">{{ vacancy.vacancies_count }}</span>
                    </div>
                </slide>
            </carousel>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-12 text-md-center">
                    <router-link to="#" class="btn btn-secondary">{{ $t("See all vacancies") }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import IndustriesService from '../../services/industriesService';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    mounted() {
        this.getVacancies();
    },
    methods: {
        async getVacancies() {
            await IndustriesService.vacancies().then(res => {
                const { vacancies_count, data } = res.data;
                
                if (vacancies_count) {
                    this.$store.dispatch('addVacanciesCount', vacancies_count);
                }

                if (data) {
                    this.$store.dispatch('addVacancies', data);
                }

            }).catch(err => {
                console.log(err);
            })
        }
    },
    computed: {
        vacancies() {
            return this.$store.getters.vacancies;
        },
        vacancyCount() {
            return this.$store.getters.vacanciesCount;
        }
    }
}
</script>