import {userGroupService} from "@/services/settings/userGroupService";
import {userTypeService} from "@/services/settings/userTypeService";

export const state = {
    userGroups: null,
    permissionTitles: null,
    maxPermissions: null,
}

export const mutations = {
    setUserGroups(state, userGroups) {
        state.userGroups = userGroups
    },
    addUserGroup(state, userGroup) {
        state.userGroups.push(userGroup)
    },
    deleteUserGroup(state, groupId) {
        const index = state.userGroups.findIndex(userGroup => userGroup.id === groupId)
        if (index >= 0) state.userGroups.splice(index, 1)
    },
}

export const actions = {

}

export const getters = {
    getUserGroupId: (state) => (name) => {
        return state.userGroups.find(userGroup => userGroup.name === name)?.id
    },
    userGroups(state, rootGetters) {
        if (! state.userGroups) {
            if (rootGetters.isSuperAdmin) {
                userTypeService.getUserTypes().then(response => state.userGroups = response.data.data)
            } else {
                userGroupService.getUserGroups().then(response => state.userGroups = response.data.data)
            }
        }
        return state.userGroups
    },
    permissionTitles(state, rootGetters) {
        if (! state.permissionTitles) {
            if (rootGetters.isSuperAdmin) {
                userTypeService.getPermissionTitles().then(response => state.permissionTitles = response.data.data)
            } else {
                userGroupService.getPermissionTitles().then(response => state.permissionTitles = response.data.data)
            }
        }
        return state.permissionTitles
    },
    maxPermissions(state, rootGetters) {
        if (! state.maxPermissions) {
            if (rootGetters.isSuperAdmin) {
                userTypeService.getMaxPermissions().then(response => state.maxPermissions = response.data.data)
            } else {
                userGroupService.getMaxPermissions().then(response => state.maxPermissions = response.data.data)
            }
        }
        return state.maxPermissions
    }
}