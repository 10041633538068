
export const state = {
    users: []
}

export const mutations = {
    setUsers(state, users) {
        state.users = users
    },
    addUser(state, user) {
        state.users.push(user)
    },
    deleteUser(state, user) {
        const index = state.users.indexOf(user)
        if (index >= 0) state.users.splice(index, 1)
    }
}

export const actions = {

}

export const getters = {

}